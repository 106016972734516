import React, { useEffect, useState } from 'react';
import DYF_Flyer from './pdf_files/DYF-Flyer.pdf'
import SchoolKidz_W_9 from './pdf_files/SS W-9 AR.pdf'
import APIConnector from "../../../apiconnector"
import CommonVariables from "../../../variables";
import { PdfDownload } from '../../../Dashboard/components/commonfunctions';
import InfoIcon from '@mui/icons-material/Info';

/**
 * This component contains reports which can be downloaded 
 * 
 */

function Forms(props) {

    const [userData, setUserData] = useState({});
useEffect(() => {
   
    GetUserInfo();
  
}, [])


const exportShopTTKitsOrderSummary = () => {
   
    var obj={
        "parameters": `&p_customerNumber=${userData.custnmbr}`, "reportname": CommonVariables.ConfigData.Reports.ViewOnlineOrdersReport
    }
    props.onLoaderRaise(true, "Generating Report ....", { marginTop: "145px" });
    APIConnector.Post(CommonVariables.urls.GetExcelReport, obj).then(function (resp) {
        props.onLoaderRaise(false);
        if (!CommonVariables.isError(resp))
            return;
        PdfDownload("ShopTTKitsOrderSummary.xlsx",resp.reportdata)
       
    });
};


const GetUserInfo = function () {
    let input = {
        userId: localStorage.getItem("userId"),
    };
    props.onLoaderRaise(true);
    APIConnector.Post(CommonVariables.urls.getUserById, input).then(function (resp) {
        props.onLoaderRaise(false);
        if (!CommonVariables.isError(resp))
            return;
        if (resp.userName) {
            setUserData(resp);
        }
    });
};
    
    return <div style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '5%'
    }}>
        <h2 style={{  marginLeft: "15px !important" }}>Misc.</h2>
        <br/>
        <a style={{ textDecoration: "underline", marginLeft: "15px !important" }} href={DYF_Flyer} target="_blank">Download Did You Forget Flyer</a>
        <a style={{ textDecoration: "underline", marginLeft: "15px !important" }} href={SchoolKidz_W_9} target="_blank">Download SchoolKidz W-9 Form</a>
        <a style={{ textDecoration: "underline", marginLeft: "15px !important" }} href="#" onClick={exportShopTTKitsOrderSummary}>View Online Orders</a>
    </div>;
};

export default Forms