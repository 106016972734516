import React, { useEffect, useState } from 'react';
import "./Home.css";
import $ from 'jquery'
import CommonVariables from "../../../variables";
import { Link, useLocation } from 'react-router-dom';
import APIConnector from "../../../apiconnector";
import AllRoutes from "../../../Routing/AllRoutes";
import "./smoothingArrow.css"
import { WhereAmI } from "../../../UserActivity";
import UserActivity from "../../../UserActivity";
import Notifications from '../../../Notifications/Messages';
import DynamicProgressBar from "./DynamicProgressBar";
import { useNavigate } from 'react-router';
import SchoolSetup from '../Images/IconSchoolSetup.png';
import Q_Revision from '../Images/GradeWorksheet.png';
import TallySheet from '../Images/TallySheet.png';
import ProgramSchedule from '../Images/ProgramSchedule.png';
import SubmitReferral from '../Images/SubmitReferral.png';
import FileDownloadIcon from '../../../Dashboard/components/Images/FiledownloadIcon.png';
import FormsReport from '../Images/FormsReport.png';

/**
 * This component renders the home page of the External Dashboard
 * 
 */

function Home(props) {
    const [gradeWorksheetsCount, setGradeWorksheetCount] = useState();
    const [orderNameEntryCount, setOrderNameEntryCount] = useState();
    const [flag, setFlag] = useState(false);
    const RoleName = CommonVariables.getlocalstorageData().RoleName;
    const [currentStage, setcurrentStage] = React.useState(0);
    const [orderFormWizardHide, setorderFormWizardHide] = React.useState(0);
    const navigate = useNavigate();
    const [userData, setUserData] = useState({});
    const SmoothingArow = (
        <div className="col-2 scroll-prompt ml-lg-auto" scroll-prompt="" style={{ opacity: 1 }} >
            <div className="scroll-prompt-arrow-container">
                <div className="scroll-prompt-arrow"><div></div></div>
                <div className="scroll-prompt-arrow"><div></div></div>
            </div>
        </div>
    );

    const loadingPanel = (
        <>
            <div className="k-loading-mask" style={{ color: "black", fontSize: "8px", width: "30%", height: "50%" }}>
                <span className="k-loading-text">Loading</span>
                <div className="k-loading-image"></div>
            </div>
        </>
    );
    const GetUserInfo = function () {
        let input = {
            userId: localStorage.getItem("userId"),
        };
        props.onLoaderRaise(true);
        APIConnector.Post(CommonVariables.urls.getUserById, input).then(function (resp) {
            props.onLoaderRaise(false);
            if (!CommonVariables.isError(resp))
                return;
            if (resp.userName) {
                setUserData(resp);
            }
        });
    };

    useEffect(() => {
        if (!RoleName || RoleName.length <= 0) {
            navigate("/");
            return;
        }
        
        APIConnector.Post(CommonVariables.urls.GetExternalDashboardCounts, {}).then(function (response) {
            if (!CommonVariables.isError(response))
                return;
            setFlag(true);
            setGradeWorksheetCount(response.gradeWorksheetsCount);
            setOrderNameEntryCount(response.orderNameEntryCount);
        });

        props.onLoaderRaise(true);
        APIConnector.Post(CommonVariables.urls.GetUserActivity, { isFromHomePage :true}).then(function (Activityresponse) {
            props.onLoaderRaise(false);
            if (!CommonVariables.isError(Activityresponse))
                return;

            if (Activityresponse.orderFormWizardHide && Activityresponse.stage === 4) {
                Activityresponse.stage = Activityresponse.stage + 1;
                setorderFormWizardHide(Activityresponse.orderFormWizardHide);
            }
            else if (Activityresponse.orderFormWizardHide) {
                setorderFormWizardHide(Activityresponse.orderFormWizardHide);
            }
            setcurrentStage(Activityresponse.stage);
            GetUserInfo();
        });
    }, [])
    // useEffect(()=>{
    //     window.confirm("Are you sure you want to change")
    // },[window.location])
    const VerifyPermissionForProvidedRoute = (RouteName) => {
        if (!RoleName || RoleName.length <= 0) {
            navigate("/");
            return;
        }

        var FilteredRoutes = AllRoutes.filter(function (EachRoute) {
            return EachRoute.path === RouteName && EachRoute.Roles.includes(RoleName.toUpperCase());
        });
        if (FilteredRoutes && FilteredRoutes.length > 0)
            return true;
        else
            return false;
    };


    return (
        <>
            <div className='background'>
                {
                    currentStage > 0 &&
                    <>
                        <div style={{ marginTop: "5px", justifyContent: "end" }} className='Headlabel row'>
                            {userData && <div className="mr-12">
                                <div><label className='Headlabel' style={{ marginBottom: "0rem" }}> Account Name : {userData.schoolName} </label></div>
                                <div><label className='Headlabel'> Account Number: {userData.custnmbr} </label></div>
                                <div><label className='Headlabel'> Current Stage: {UserActivity.GetUserActivityDescription(currentStage)} </label></div>
                            </div>}
                        </div>
                        <DynamicProgressBar stage={currentStage} />
                    </>
                }
                <div className="row mx-auto mr-0 pt-10">
                    {
                        VerifyPermissionForProvidedRoute("/externaldashboard/schoolinfo") &&
                        (<Link to='schoolinfo' className="col-12 col-md-4 my-3 d-flex align-items-stretch hover:-translate-y-2 duration-300">
                            <div className="card card-body-blue col-sm-12 p-0 mx-0 text-white hover:shadow-lg duration-300 hover:shadow-gray-500 ">
                                <div className="card-text row p-3">
                                    <div className="col-12 d-flex justify-between">
                                        <h5 className='inline'>School Setup</h5>
                                        <div className="col-4 text-right inline">
                                            <img className="symbol-dashboard-blue" alt="School Setup Icon" src={SchoolSetup} />
                                        </div>
                                    </div>
                                    <p className='pl-3 pr-2 pb-2 pt-2 inline w-4/6'>
                                        Enter school info, contact info, additional optional programs, and tax-exempt information
                                    </p>
                                    {currentStage === WhereAmI.SchoolSetup && SmoothingArow}

                                </div>
                                <button className="card-link-blue text-white button-hover-effect button-hover-effect-blue p-2 text-left mt-auto hover:text-yellow-00">
                                    More Info
                                </button>
                            </div>
                        </Link>)
                    }
                    {
                        VerifyPermissionForProvidedRoute("/externaldashboard/grades") &&
                        (<Link to='grades' className="col-12 col-md-4 my-3 d-flex align-items-stretch hover:-translate-y-2 duration-300">
                            <div className="card card-body-blue hover:shadow-lg duration-300 hover:shadow-gray-500 col-12 p-0 mx-0 text-white">
                                <div className="card-text row p-3">
                                    <div className="col-12 d-flex justify-between">
                                        <h5 className='inline'>Grade Worksheets</h5><div className="col-4 text-right inline">
                                            <img className="symbol-dashboard-blue" alt="Q_Revision Icon" src={Q_Revision} />
                                        </div>
                                    </div>
                                    <p className='pl-3 pr-2 pb-2 pt-2 inline w-4/6'>
                                        Review, revise, approve, and print grade worksheets
                                    </p>
                                    {currentStage === WhereAmI.GradeWorksheet && SmoothingArow}

                                </div>
                                <button className="card-link-blue text-white button-hover-effect button-hover-effect-blue p-2 text-left mt-auto hover:text-yellow-00">
                                    More Info
                                </button>
                            </div>
                        </Link>)
                    }
                    {
                        VerifyPermissionForProvidedRoute("/externaldashboard/ProgramScheduleWizard") &&
                        (<Link to='ProgramScheduleWizard' className="col-12 col-md-4 my-3 d-flex align-items-stretch hover:-translate-y-2 duration-300">
                            <div className="card card-body-blue col-sm-12 p-0 mx-0 text-white hover:shadow-lg duration-300 hover:shadow-gray-500 ">
                                <div className="card-text row p-3">
                                    <div className="col-12 d-flex justify-between">
                                        <h5 className='inline'>Program Schedule</h5>
                                        <div className="col-4 text-right inline">
                                            <img className="symbol-dashboard-blue" alt="OrderProof Icon" src={ProgramSchedule} />
                                        </div>
                                    </div>
                                    <p className='pl-3 pr-2 pb-2 pt-2 inline w-4/6'>
                                        Setup sale type, sale dates, parent prices, and order forms/flyer
                                    </p>
                                    {currentStage === WhereAmI.ProgramSchedule && SmoothingArow}

                                </div>
                                <button className="card-link-blue text-white button-hover-effect button-hover-effect-blue p-2 text-left mt-auto hover:text-yellow-00">
                                    More Info
                                </button>
                            </div>
                        </Link>)
                    }
                    {
                        !orderFormWizardHide && VerifyPermissionForProvidedRoute("/externaldashboard/OrderEntryWizard") &&
                        (<Link to='OrderEntryWizard' className="col-12 col-md-4 my-3 d-flex align-items-stretch hover:-translate-y-2 duration-300">
                            <div className="card card-body-blue col-sm-12 p-0 mx-0 text-white hover:shadow-lg duration-300 hover:shadow-gray-500 ">
                                <div className="card-text row p-3">
                                    <div className="col-12 d-flex justify-between">
                                        <h5 className='inline'>Order Entry - Tally Sheet</h5>
                                        <div className="col-4 text-right inline">
                                            <img className="symbol-dashboard-blue" alt="OrderProof" src={TallySheet} />
                                        </div>
                                    </div>
                                    <p className='pl-3 pr-2 pb-2 pt-2 inline w-4/6'>
                                        Input paper orders (if applicable)
                                    </p>
                                    {currentStage === WhereAmI.TallySheet && SmoothingArow}

                                </div>
                                <button className="card-link-blue text-white button-hover-effect button-hover-effect-blue p-2 text-left mt-auto hover:text-yellow-00">
                                    More Info
                                </button>
                            </div>
                        </Link>)
                    }
                    {
                        VerifyPermissionForProvidedRoute("/externaldashboard/forms") &&
                        (<Link to='forms' className="col-12 col-md-4 my-3 d-flex align-items-stretch hover:-translate-y-2 duration-300">
                            <div className="card card-body-blue hover:shadow-lg duration-300 hover:shadow-gray-500 col-12 p-0 mx-0 text-white">
                                <div className="card-text row p-3">
                                    <div className="col-12 d-flex justify-between">
                                        <h5 className='inline'>Online Status Reports and Forms</h5>
                                        <div className="col-4 text-right inline">
                                            <img className="symbol-dashboard-blue" alt="Print Icon" src={FormsReport} />
                                        </div>
                                    </div>
                                    <p className='pl-3 pr-2 pb-2 pt-2 inline w-4/6'>
                                        View Online Orders, W-9 and other forms
                                    </p>

                                </div>
                                <button className="card-link-blue text-white button-hover-effect button-hover-effect-blue p-2 text-left mt-auto hover:text-yellow-00">
                                    More Info
                                </button>
                            </div>
                        </Link>)
                    }
                    {
                        VerifyPermissionForProvidedRoute("/externaldashboard/referral") &&
                        (<Link to='referral' className="col-12 col-md-4 my-3 d-flex align-items-stretch hover:-translate-y-2 duration-300">
                            <div className="card card-body-blue hover:shadow-lg duration-300 hover:shadow-gray-500 col-12 p-0 mx-0 text-white">
                                <div className="card-text row p-3">
                                    <div className="col-12 d-flex justify-between">
                                        <h5 className='inline'>Submit Referral</h5>
                                        <div className="col-4 text-right inline">
                                            <img className="symbol-dashboard-blue" alt="Print Icon" src={SubmitReferral} />
                                        </div>

                                    </div>
                                    <p className='pl-3 pr-2 pb-2 pt-2 inline w-4/6'>
                                        Refer a school to SchoolKidz
                                    </p>

                                </div>
                                <button className="card-link-blue text-white button-hover-effect button-hover-effect-blue p-2 text-left mt-auto hover:text-yellow-00">
                                    More Info
                                </button>
                            </div>
                        </Link>)
                    }
                </div>
            </div>
        </>
    )
}

export default Home