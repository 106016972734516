import React, { useEffect, useState } from 'react';
import { Field } from '@progress/kendo-react-form';
import { FormRadioGroup } from '../form-components';
import { requiredValidator } from '../validators';
import PaperOnlyPdf from '../OrderFormPDFS/Paper Ordering Only.pdf'
import PaperAndOnlinePdf from '../OrderFormPDFS/Paper and Online Ordering.pdf'
import OnlinePdf from '../OrderFormPDFS/Online Ordering Only.pdf'


const OrderType = (props) => {
    const data = [
        {
            label: 'Online Only',
            value: 'OnlineOnly',
            style: { borderColor: "#bab4b4", border: "2px solid", backgroundSize: "0px", boxShadow: "0 0 0 2px rgb(44 41 40 / 30%)" }
        }, {
            label: 'Paper Only (School Orders All)',
            value: 'PaperOnly',
            style: { borderColor: "#bab4b4", border: "2px solid", backgroundSize: "0px", boxShadow: "0 0 0 2px rgb(44 41 40 / 30%)" }
        }
    ];

    return <div>
        <br />
        <h5 style={{ textAlign: "center" }} className="wizardStepHeadLabel">
            You have signed up for {props.formState.programDeliveryOutModel.homeDelivery === true ? "home delivery" : "school delivery"}
            <br></br> 
            {props.formState.programDeliveryOutModel.homeDelivery && "Orders will be delivered anytime after your school's ordering session has closed up to until the Saturday of the requested delivery week."}
        </h5>
        
        {
            props.formState.programDeliveryOutModel.homeDelivery === false && <div>
                <label className="fieldlabel">
                    Please select your ordering method.
                </label>
                <Field key={'programDeliveryOutModel.orderType'} id={'programDeliveryOutModel.orderType'}
                    name={'programDeliveryOutModel.orderType'} layout={'vertical'} component={FormRadioGroup}
                    data={data}
                    onChange={props.OrderFormTypechangeEvent}
                    validator={requiredValidator} />
            </div>
        }
    </div>;
};

export default OrderType;